.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.dev-btn {
	position: fixed !important;
	bottom: 0 !important;
	right: 0 !important;
	background-color: #314293 !important;
	color: white !important;
	margin: 0 0.5em 0.5em 0 !important;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.react-stars {
	width: 100%;
	display: flex;
	justify-content: space-around;
	outline: none;
}

.viewer-inner-page {
	padding: 0px !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
